<template>
  <div :class="`${node.type === 'endpoint' ? 'custom' : 'p-1'}`">
    <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
      <div :class="{ 'ml-1': node.type !== 'endpoint' }">
        <div class="d-flex justify-content-between">
          <div class="node" @click="expanded = !expanded">
            <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658' }}</span>
            <span v-if="node.type == 'group'">{{ node.name }}</span>
          </div>
        </div>

        <div
            class="d-flex cursor-pointer justify-content-between rounded-8 p-1 mb-1 bg-black-light"
            v-if="node.type == 'endpoint' && node.service_general_status === 'occupied'"
            @click="openPatientDetailsModal(node)"
        >
          <p class="mb-0">{{ node.name }} </p>
        </div>

        <div
            class="d-flex cursor-initial justify-content-between rounded-8 p-1 mb-1 border-black-light"
            v-if="node.type == 'endpoint' && node.service_general_status != 'occupied'"
        >
          <p class="mb-0">{{ node.name }}</p>
        </div>

      </div>
    </div>

    <hr class="mb-1 mt-0" v-if="type == 'group'">

    <template v-if="expanded">
      <TreeBrowser
          v-for="item in node.child"
          :key="item.name"
          :parent="node"
          :depth="depth + 1"
          :node="item"
      />
    </template>

    <PatientDetailsModal
        v-if="$store.state.isModalOpenTwo && patientDetails.id"
        :patientDetails="patientDetails"
    />

    <Loader v-if="loading"/>

  </div>
</template>

<script setup>
import {computed, inject, ref} from "vue";
import TreeBrowser from '@/components/molecule/company/hospital/service-resources/ServiceResourceStatusTree'
import PatientDetailsModal
  from '@/components/molecule/company/hospital/service-resources/ServiceResourceStatusPatientModal'
import {useStore} from "vuex";
import handleHospital from "@/services/modules/hospital";
import {useRoute} from "vue-router";
import Loader from '@/components/atom/LoaderComponent'

const expanded = ref(true)
const $store = useStore();
const $route = useRoute();
const {fetchSingleNote} = handleHospital();
const showError = inject('showError');

const props = defineProps({
  node: Object,
  depth: {
    type: Number,
    default: 0
  },
  parent: {
    default: []
  }
})

const companyId = $route.params.companyId;
const patientDetails = ref({})
const loading = ref(false)

const openPatientDetailsModal = async (node) => {
  const companyQuery = `?company_id=${companyId}`;
  loading.value = true
  await fetchSingleNote(companyQuery, node.ipd_register.id).then(res => {
    if (!res.status){
      showError(res.message)
      return;
    }
    if (res.data) {
      patientDetails.value = res.data;
      $store.state.isModalOpenTwo = true;
    }
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const hasChildren = computed(() => props.node.child.length)

</script>

<style scoped>
.endpoint {
  padding: 6px;
  margin: 5px;
}

.g-l-2 {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 10px;
}

.bg-black-light {
  background: #00000024;
}
.bg-black-light:hover {
  background: #DBE7F2;
}

.node {
  cursor: pointer;
}

.node-hover {
  padding: 10px 2px;
}

.node-hover:hover {
  background: #fffeec;
}

.border-black-light {
  border: 1px solid #00000024;
}

.custom {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}
</style>