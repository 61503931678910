<template>
  <div v-if="$store.state.isModalOpenTwo" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered" ref="modalRef">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ 'Patient Details' }}</h1>
              <button
                  @click="onClickClose"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>

        <hr class="mt-0 mb-0">

        <div class="pb-2">
          <div class="row">
            <div class="col-11 py-1 mx-auto">
              <label class="form-label">Patient Details</label>
              <div class="input-group">
                <input type="text" class="form-control" :value="[patientDetails.patient.full_name, formatPatientInfo(patientDetails.patient)].join(' ')" disabled>
                <input type="text" class="form-control" :value="formatPatientAdditionalInfo(patientDetails.patient)" disabled>
              </div>
            </div>
            <div class="col-11 pb-1 mx-auto">
              <label class="form-label">Admission No | Date & Time</label>
              <div class="input-group">
                <input type="text" class="form-control" :value="[patientDetails.admission_no, patientDetails.formattedAdmissionData].join(' ')" disabled>
              </div>
            </div>
            <div class="col-11 pb-1 mx-auto">
              <label class="form-label">Ward/Bed/Cabin</label>
              <div class="input-group">
                <input type="text" class="form-control" :value="patientDetails.service_resource.name" disabled>
              </div>
            </div>
            <div class="col-11 pb-1 mx-auto">
              <label class="form-label">Consultant(Referred Doctor)</label>
              <div class="input-group">
                <input type="text" class="form-control" :value="patientDetails.human_resource.name" disabled>
              </div>
            </div>
            <div class="col-11  mx-auto">
              <label class="form-label">Admitted By</label>
              <div class="input-group">
                <input type="text" class="form-control" :value="patientDetails.admitted_by_user.full_name" disabled>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { dateOfBirthConvertToAge } from '@/services/utils/global.js'
import { lowerCase } from 'lodash'

const $store = useStore();
const $route = useRoute();

const props = defineProps({
  patientDetails: {
    type: Object,
    required: true
  }
});

const onClickClose = () => {
  $store.state.isModalOpenTwo = false;
  props.patientDetails = {};
}

const formatPatientInfo = (item) => {
  let text = [];
  if (item.birthday) {
    const { year } = dateOfBirthConvertToAge(item.birthday);
    text.push(`${year}years`)
  }
  if (item.gender) {
    text.push(lowerCase(item.gender));
  }
  return `(${text.join(', ')})`;
}

const formatPatientAdditionalInfo = (item) => {
  let text = '';
  if(item.mobile) text += item.mobile;
  if((item.mobile && item.id)) text += ' | ';
  if(item.serial_no) text += item.serial_no;
  return text;
}

</script>
